@import "src/mixins/styles";

$color-exelent: #a3d320;
$color-good: #f3c00b;
$color-normal: #f96239;
$color-bad: #f9393b;

.userStatisticCard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 400px;
  height: fit-content;
  padding: 40px 16px;
  gap: 32px;
  .logo-image-container,
  .footer-logo-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    img {
      height: 100%;
    }
  }

  .userStatisticCard-header {
    display: flex;
    flex-direction: column;

    font-family: TT Norms Pro !important;
    font-size: 26px;
    font-weight: 500;

    letter-spacing: -0.03em;
    text-align: center;

    .userName {
      color: #f3c00b;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .userAction {
    }

    .userStatisticCard-period {
    }
  }

  .summary-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .knowledges {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .knowledgesKnow {
        display: flex;
        flex-direction: column;
        min-width: 220px;
        padding: 16px;
        border-radius: 8px;
        background-color: #15a9f412;
        position: relative;
        overflow: hidden;
        .knowledgesKnow-svgIcon {
          position: absolute;
          right: -8px;
          top: -0px;
          width: 88px;
          height: 88px;
          opacity: 0.6;
        }
        .knowledgesKnowValuAndDelta {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;

          .knowledgesKnowValue {
            font-family: TT Norms Pro !important;
            font-size: 40px;
            font-weight: 500;
            line-height: 47.92px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #15a9f4;
          }

          .knowledgesKnowDelta {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: TT Norms Pro !important;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: white;
            background-color: #15a9f4;
            height: 30px;
            padding: 7px 7px;
            border-radius: 10px;
          }
        }

        .knowledgesKnowText {
          font-family: TT Norms Pro !important;
          font-size: 17px;
          font-weight: 500;
          line-height: 20.37px;
          text-align: left;
          color: #15a9f4;
        }
      }
      .knowledgesLearn {
        display: flex;
        flex-direction: column;
        min-width: 130px;
        background: #7b78ff12;
        padding: 16px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        .knowledgesLearn-svgIcon {
          position: absolute;
          right: -8px;
          top: -0px;
          width: 88px;
          height: 88px;
          opacity: 0.6;
        }
        .knowledgesLearnValueAndDelta {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          .knowledgesLearnValue {
            font-family: TT Norms Pro !important;
            font-size: 40px;
            font-weight: 500;
            line-height: 47.92px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #7b78ff;
          }

          .knowledgesLearnDelta {
            font-family: TT Norms Pro !important;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: +0.1em;
            text-align: left;
            color: white;
            background-color: #7a78ff86;
            height: 30px;
            padding: 5px 7px;
            border-radius: 10px;
          }
        }
        .knowledgesLearnText {
          font-family: TT Norms Pro !important;
          font-size: 17px;
          font-weight: 500;
          line-height: 20.37px;
          text-align: left;
          color: #7b78ff;
        }
      }
    }

    .knowledgeGroups {
      display: flex;
      flex-direction: row;
      background-color: #a3d32012;
      justify-content: space-between;
      height: 80px;
      padding: 16px;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      .knowledgeGroups-svgIcon {
        position: absolute;
        right: -47px;
        top: -35px;
        width: 144px;
        height: 144px;
        //opacity: 0.6;
      }
      .allGroups {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        .allGroupsValue {
          color: #a3d320;
          font-family: TT Norms Pro !important;
          font-size: 28px;
          font-weight: 500;
        }

        .allGroupsText {
          color: #a3d320;
          font-family: TT Norms Pro !important;
          font-size: 28px;
          font-weight: 500;
        }
      }

      .deltaGroups {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        z-index: 10;
        .deltaGroupsValue {
          display: flex;
          font-family: TT Norms Pro !important;
          font-size: 16px;
          font-weight: 500;
          // color:#374151
          color: #61738f;
        }

        .deltaGroupsBattery {
          display: flex;
          width: fit-content;
          align-items: center;
          height: 40px;

          .batteryBody {
            display: flex;
            flex-direction: row;
            border: 3px solid #dfe3e9;
            height: 40px;
            border-radius: 12px;
            padding: 3px 3px;
            gap: 2px;
            width: 120px;
            background-color: white;

            .batteryElement {
              display: flex;
              height: 100%;
              width: 100%;
              background-color: #dfe3e9;
              border-radius: 4px;
              &.know {
                background-color: #a3d320;
              }
              &:first-child {
                border-radius: 8px 4px 4px 8px;
              }
              &:last-child {
                border-radius: 4px 8px 8px 4px;
              }
            }
          }
          .batteryPlus {
            display: flex;
            border: 3px solid #dfe3e9;
            height: 20px;
            width: 5px;
            margin-left: -2px;
            border-radius: 20px;
          }
        }
      }
    }

    .days {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      background-color: #7b78ff08;
      padding: 16px;

      .daysWithBulbee {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        align-items: center;
        .daysWithBulbeeValue {
          font-family: TT Norms Pro !important;
          font-size: 28px;
          font-weight: 500;
          line-height: 33.54px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #7b78ff;
        }

        .daysWithBulbeeDelta {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: fit-content;
          font-family: TT Norms Pro !important;
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: #ffffff;
          padding: 3px 5px;
          border-radius: 10px;
          background-color: #7a78ff8e;
        }

        .daysWithBulbeeText {
          font-family: TT Norms Pro !important;
          font-size: 28px;
          font-weight: 500;
          line-height: 33.54px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #7b78ff;
        }
      }

      .daysWithGamePlay {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .daysWithGamePlayValue {
          font-family: TT Norms Pro !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #7b78ff;
        }
        .daysWithGamePlayDelta {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: TT Norms Pro !important;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #ffffff;
          padding: 2px 5px;
          border-radius: 7px;
          background-color: #7a78ff8e;
        }

        .daysWithGamePlayText {
          font-family: TT Norms Pro !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #7b78ff;
        }
      }
    }

    .playTime {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      background-color: #f9623908;
      padding: 16px;
      width: 100%;
      .totalPlayTime {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 100%;
        justify-content: center;
        align-items: center;
        .totalPlayTimeValue {
          font-family: TT Norms Pro !important;
          font-size: 28px;
          font-weight: 500;
          line-height: 33.54px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #f96239;
        }

        .totalPlayTimeDelta {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: fit-content;
          font-family: TT Norms Pro;
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          background-color: #f9633979;
          padding: 5px 10px;
          border-radius: 10px;
          color: white;
          gap: 5px;
        }

        .totalPlayTimeText {
          font-family: TT Norms Pro;
          font-size: 28px;
          font-weight: 500;
          line-height: 33.54px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #f96239;
        }
      }

      .averagePlayTime {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        .averagePlayTimeValue {
          font-family: TT Norms Pro !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #f96239;
        }

        .averagePlayTimeDelta {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: TT Norms Pro !important;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #ffffff;
          padding: 2px 5px;
          border-radius: 7px;
          color: white;
        }

        .averagePlayTimeText {
          font-family: TT Norms Pro !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #f96239;
        }
      }
    }
  }
  .knowledges-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 24px;
    .knowledgesGroups-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: TT Norms Pro !important;
      font-size: 26px;
      font-weight: 500;
      line-height: 26px;
      width: fit-content;
    }
    .knowledgesGroups {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      .group {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 14px;
        border: 2px solid #dfe3e9;
        box-shadow: 0px 85px 24px 0px rgba(87, 111, 158, 0), 0px 14px 14px 0px rgba(87, 111, 158, 0.02),
          0px 3px 7px 0px rgba(87, 111, 158, 0.02);
        width: 100%;
        padding: 8px;
        .groupHeader {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          padding: 10px;
          gap: 20px;
          .groupBattery-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              height: 70px;
            }
            .battery {
              display: flex;
              flex-direction: column;
              height: fit-content;
              align-items: center;
              .batteryPlus {
                display: flex;
                background-color: #dfe3e9;
                height: 3px;
                width: 13px;
                margin-bottom: -1px;
                border-radius: 20px;
              }
              .batteryBody {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 3px solid #dfe3e9;
                min-height: 70px;
                height: 70px;
                width: 45px;
                border-radius: 12px;
                padding: 2px;
                gap: 2px;
                .batteryElement {
                  display: flex;
                  height: 100%;
                  width: 100%;
                  background-color: #dfe3e9;
                  border-radius: 2px;
                  &.know {
                    background-color: #a3d320;
                  }
                  &:first-child {
                    border-radius: 10px 10px 0px 0px;
                  }
                  &:last-child {
                    border-radius: 0px 0px 10px 10px;
                  }
                }
              }
            }
          }
          .groupInfo-block {
            display: flex;
            flex-direction: column;
            gap: 3px;
            .groupKnowledgesStatus {
              display: flex;
              flex-direction: row;
              font-family: TT Norms Pro !important;
              font-size: 14px;
              font-weight: 400;
              color: #8691a5;
            }
            .groupName {
              display: block;
              flex-direction: row;
              font-family: TT Norms Pro !important;
              font-size: 20px;
              font-weight: 500;
              color: #374151;
              &::first-letter {
                text-transform: uppercase;
              }
            }
            .groupStatusAndCorona {
              display: flex;
              flex-direction: row;
              gap: 10px;
              .groupStatus {
                display: flex;
                flex-direction: row;
                font-family: TT Norms Pro !important;
                font-size: 14px;
                font-weight: 500;
                color: #7b78ff;
                background-color: #7a78ff36;
                border-radius: 6px;
                padding: 4px 6px;
                width: fit-content;
                &.know {
                  color: #a3d320;
                  background-color: #a3d32036;
                }
              }
              .level-line {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                width: 100px;
                height: 26px;
                padding: 0px 2px 0px 5px;
                gap: 5px;
                border-radius: 8px;
                background-color: #f3c00b1a;
                .image-container {
                  display: flex;
                  width: 20px;
                  height: 20px;
                  align-items: center;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .level-label {
                  display: flex;
                  align-items: center;
                  width: fit-content;
                  height: 100%;
                  font-family: TT Norms Pro !important;
                  font-size: 14px;
                  font-weight: 500;

                  text-align: left;
                  color: #f3c00b;
                }
              }
            }
          }
        }
        .groupKnowledges {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 5px;
          .groupKnowledge {
            display: flex;
            flex-direction: row;
            padding: 8px;
            gap: 8px;
            width: 170px;
            height: 85px;
            border-radius: 8px;
            position: relative;
            &.greenBorder {
              border: 2px solid #a3d320;
            }
            .groupKnowledgeBattery-block {
              display: flex;
              flex-direction: row;
              align-items: center;
              position: relative;
              img {
                height: 70px;
              }
              .battery {
                display: flex;
                flex-direction: column;
                height: fit-content;
                align-items: center;
                height: 70px;
                width: 45px;
                .batteryPlus {
                  display: flex;
                  background-color: #dfe3e9;
                  height: 3px;
                  width: 13px;
                  margin-bottom: -1px;
                  border-radius: 3px 3px 0px 0px;
                }
                .batteryBody {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  border: 3px solid #dfe3e9;
                  height: 67px;
                  min-height: 67px;
                  min-width: 45px;
                  max-width: 45px;
                  border-radius: 12px;
                  overflow: hidden;
                  box-sizing: border-box;
                  position: relative;
                  .fillerLines {
                    display: flex;
                    width: 100%;
                    position: absolute;
                  }
                  .batteryFiller {
                    display: flex;
                    width: 100%;
                    border-radius: 5px 5px 0px 0px;
                    overflow: hidden;
                    &.delta {
                      margin-bottom: -5px;
                    }

                    &.color60 {
                      background-color: hsl(76, 74%, 48%);
                    }
                    &.colorDelta60 {
                      background-color: hsla(76, 74%, 48%, 0.7);
                    }
                    &.color55 {
                      background-color: hsla(57, 75%, 56%);
                    }
                    &.colorDelta55 {
                      background-color: hsla(57, 75%, 56%, 0.7);
                    }
                    &.color50 {
                      background-color: hsl(55, 78%, 57%);
                    }
                    &.colorDelta50 {
                      background-color: hsla(55, 78%, 57%, 0.7);
                    }
                    &.color45 {
                      background-color: hsl(49, 85%, 50%);
                    }
                    &.colorDelta45 {
                      background-color: hsla(49, 85%, 50%, 0.7);
                    }
                    &.color40 {
                      background-color: hsl(41, 91%, 52%);
                    }
                    &.colorDelta40 {
                      background-color: hsla(41, 91%, 52%, 0.7);
                    }
                    &.color35 {
                      background-color: hsl(31, 92%, 56%);
                    }
                    &.colorDelta35 {
                      background-color: hsla(31, 92%, 56%, 0.7);
                    }
                    &.color30 {
                      background-color: hsl(21, 93%, 58%);
                    }
                    &.colorDelta30 {
                      background-color: hsla(21, 93%, 58%, 0.7);
                    }
                    &.color25 {
                      background-color: hsl(12, 94%, 61%);
                    }
                    &.colorDelta25 {
                      background-color: hsla(12, 94%, 61%, 0.7);
                    }
                    &.color20 {
                      background-color: hsl(8, 94%, 60%);
                    }
                    &.colorDelta20 {
                      background-color: hsla(8, 94%, 60%, 0.7);
                    }
                    &.color15 {
                      background-color: hsl(359, 94%, 61%);
                    }
                    &.colorDelta15 {
                      background-color: hsla(359, 94%, 61%, 0.7);
                    }
                    &.color10 {
                      background-color: hsl(359, 94%, 61%);
                    }
                    &.colorDelta10 {
                      background-color: hsla(359, 94%, 61%, 0.7);
                    }
                  }
                }
              }
            }
            .knowInPeriod {
              position: absolute;
              top: 0;
              right: 0;
              padding: 1px 5px 2px 7px;
              background-color: #a3d320;
              color: white;
              border-radius: 0px 0px 0px 8px;
              font-family: TT Norms Pro !important;
              font-size: 14px;
              font-weight: 500;
            }
            .groupKnowledgeInfo-block {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 5px;
              position: relative;
              width: 100%;
              //padding-top: 15px;

              .knowledgeName {
                display: block;
                font-family: TT Norms Pro !important;
                font-size: 16px;
                font-weight: 500;
                color: #374151;
                &::first-letter {
                  text-transform: uppercase;
                }
              }
              .knowStatus {
                display: flex;
                flex-direction: row;
                font-family: TT Norms Pro !important;
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px;
                padding: 1px 6px;
                color: #a3d320;
                background-color: #a3d32036;
                width: fit-content;
              }
              .knowProgress {
                display: flex;
                flex-direction: row;
                gap: 4px;
                align-items: center;
                .knowProgressValue-block {
                  display: flex;
                  .knowProgressValue {
                    display: flex;
                    font-family: TT Norms Pro !important;
                    font-size: 19px;
                    font-weight: 500;
                    color: #374151;
                  }
                }
                .knowProgressDelta-Block {
                  display: flex;
                  flex-direction: row;
                  gap: 2px;
                  align-items: center;
                  background-color: #efeeff;
                  border: 1px solid #7b78ff;
                  border-radius: 24px;
                  padding: 1px 5px 1px 3px;
                  font-size: 14px;
                  &.incraseColor {
                    background-color: hsla(77, 62%, 77%, 0.2);
                    border: 1px solid #a3d320;
                  }
                  &.leftPadding {
                    padding: 1px 5px 1px 5px;
                  }
                  .arrow {
                    display: none;
                    height: 15px;
                    width: 15px;
                    color: #7b78ff;
                    &.up {
                      display: flex;
                    }
                  }
                  .knowProgressDelta {
                    font-size: 13px;
                    font-weight: 500;
                    font-family: TT Norms Pro !important;
                    color: #7b78ff;
                    &.incraseColor {
                      color: #a3d320;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
