@import "src/mixins/styles";
.createdfor {
  font-family: "TT Norms Pro";
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .createdfor-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;

    h2 {
      font-weight: 500;
      max-width: 524px;
      color: #374151;
      font-size: 46px;
      line-height: 90%;
      text-align: center;
    }

    p {
      max-width: 571px;
      color: #6b7280;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
    }
  }

  .cards {
    width: 100%;
    display: flex;

    @media screen and (max-width: $media-query-xl) {
    }
    @media screen and (max-width: $media-query-l) {
    }
    @media screen and (max-width: $media-query-m) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: $media-query-s) {
    }
    .card {
      padding: 16px;
      gap: 11px;
      flex: 0 1 auto;
      // height: fit-content;
      @media screen and (max-width: $media-query-xl) {
      }
      @media screen and (max-width: $media-query-l) {
        width: 200px;
      }
      @media screen and (max-width: $media-query-m) {
        width: 200px;
      }
      @media screen and (max-width: $media-query-s) {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
      }
      p {
        //min-height: 166px;
        color: #374151;
        font-size: $font-size-xl;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -1px;
        @media screen and (max-width: $media-query-m) {
          font-size: $font-size-l;
        }
        @media screen and (max-width: $media-query-s) {
          font-size: $font-size-xml;
        }
      }
    }
  }
}
