@import "src/mixins/styles";
.contacts-page {
  display: flex;
  width: 100%;
  border-radius: 15px;
  //min-height: 65vh;
  height: calc(100%);
  backdrop-filter: blur(14px);
  background: url("../../../assets/images/Login/login_bg.svg") center center no-repeat, rgba(255, 255, 255, 0.9);

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 0 auto;
    padding: 0 120px;
    max-width: 1440px;
    height: 73vh;
    backdrop-filter: blur(14px);
    @media screen and (max-width: $media-query-xl) {
    }
    @media screen and (max-width: $media-query-l) {
      height: 69vh;
    }
    @media screen and (max-width: $media-query-m) {
      height: 65vh;
    }
    @media screen and (max-width: $media-query-s) {
      height: 50vh;
      padding: 0 32px;
    }

    p {
      font-family: "TT Norms Pro", sans-serif;
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: black;
    }

    h1 {
      font-family: "TT Norms Pro", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 58px;
      text-align: center;
      color: black;
      @media screen and (max-width: $media-query-m) {
      }
      @media screen and (max-width: $media-query-s) {
      }
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      text-decoration: none;
      color: #ffb300;
      @media screen and (max-width: $media-query-m) {
        font-size: 14px;
      }
    }
  }
}
