@import "src/mixins/styles";
.aboutBulbee {
  display: flex;
  flex-direction: column;

  .aboutBulbee-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;

    .mainheader {
      display: flex;
      width: fit-content;
      font-weight: 500;
      font-family: "TT Norms Pro";
      color: #374151;
      font-size: 46px;
      letter-spacing: -1px;
    }

    .subheader {
      display: flex;
      width: fit-content;
      font-weight: 500;
      font-family: "TT Norms Pro";
      color: #6b7280;
      font-size: 18px;
    }
  }

  .cards {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @keyframes grow {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    .card {
      display: flex;
      position: relative;
      width: 590px;
      aspect-ratio: 16/9;
      border-radius: 32px;
      display: flex;
      @media screen and (max-width: $media-query-xl) {
      }
      @media screen and (max-width: $media-query-l) {
      }
      @media screen and (max-width: $media-query-m) {
      }
      @media screen and (max-width: $media-query-s) {
        padding: 0 20px;
        border-radius: 16px;
        height: 100px;
      }

      &.first {
        background: #a3d320;
      }
      &.sec {
        background: #ffcd1c;
      }
      &.third {
        background: #7b78ff;
      }
      &.fourth {
        background: #ef936f;
      }
      .card-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-self: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h4 {
          font-weight: 500;
          font-family: "TT Norms Pro";
          max-width: 426px;
          color: #fff;
          font-size: $font-size-xxl;
          line-height: 90%;
          letter-spacing: -1px;
          text-align: left;
          padding: 0 10px;
          @media screen and (max-width: $media-query-l) {
            font-size: $font-size-xxl;
          }
          @media screen and (max-width: $media-query-m) {
            font-size: $font-size-xxl;
          }
          @media screen and (max-width: $media-query-s) {
            font-size: $font-size-l;
            text-align: center;
          }
        }
        p {
          font-weight: 400;
          font-family: "TT Norms Thin";
          text-align: left;
          max-width: 426px;
          color: #fff;
          font-size: $font-size-xml;
          line-height: 120%;
          display: block;
          padding: 0 10px;
          padding-top: 15px;
          @media screen and (max-width: $media-query-l) {
          }
          @media screen and (max-width: $media-query-m) {
          }
          @media screen and (max-width: $media-query-s) {
            font-size: $font-size-ml;
            display: none;
          }
          @media screen and (min-width: $media-query-s) {
          }
        }
      }
    }
  }
}
