body {
  margin: 0;
  font-family:
    "TT Norms Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #e8e8e8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro Bold"),
    local("TTNormsPro-Bold"),
    url("assets/fonts/TTNormsPro-Bold.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro Thin"),
    local("TTNormsPro-Thin"),
    url("assets/fonts/TTNormsPro-Thin.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro ExtraLight"),
    local("TTNormsPro-ExtraLight"),
    url("assets/fonts/TTNormsPro-ExtraLight.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro Light"),
    local("TTNormsPro-Light"),
    url("assets/fonts/TTNormsPro-Light.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro Regular"),
    local("TTNormsPro-Regular"),
    url("assets/fonts/TTNormsPro-Regular.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro Medium"),
    local("TTNormsPro-Medium"),
    url("assets/fonts/TTNormsPro-Medium.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src:
    local("TT Norms Pro ExtraBold"),
    local("TTNormsPro-ExtraBold"),
    url("assets/fonts/TTNormsPro-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro ExtraBlack";
  src:
    local("TT Norms Pro ExtraBlack"),
    local("TTNormsPro-ExtraBlack"),
    url("assets/fonts/TTNormsPro-ExtraBlack.woff2") format("woff2"),
    url("assets/fonts/TTNormsPro-ExtraBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
