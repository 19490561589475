@import "src/mixins/styles";
.overview {
  font-family: "TT Norms Pro";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;

  @media screen and (max-width: $media-query-l) {
    //font-size: $font-size-l;
  }
  @media screen and (max-width: $media-query-m) {
    //font-size: $font-size-ml;
    gap: 10px;
  }
  @media screen and (max-width: $media-query-s) {
    gap: 5px;
    //font-size: $font-size-m;
  }

  .overview-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 32px;
    height: 100%;
    align-items: center;
    .overview-text {
      font-weight: 500;
      color: #374151;
      font-size: $font-size-xl;
      font-style: normal;
      line-height: 95%;
      letter-spacing: -1px;
      text-align: center;

      span {
        //padding-left: 20px;
        color: #ffb300;
        line-height: 95%;
        letter-spacing: -1px;
      }

      @media screen and (max-width: $media-query-l) {
        font-size: $font-size-l;
      }
      @media screen and (max-width: $media-query-m) {
        font-size: $font-size-ml;
      }
      @media screen and (max-width: $media-query-s) {
        font-size: $font-size-ml;
      }
    }

    .wave-icon {
      width: fit-content;
      padding: 10px 0 10px 0;
      @media screen and (max-width: $media-query-l) {
        transform: scale(0.8);
        padding: 5px 0 0px 0;
      }
      @media screen and (max-width: $media-query-m) {
        transform: scale(0.6);
        padding: 0px 0;
      }
      @media screen and (max-width: $media-query-s) {
        transform: scale(0.5);
        padding: 0px 0;
      }
    }
  }
  .overview-bottom {
    width: 100%;
    //height: 100%;
    display: flex;
    position: relative;

    .video-wrapper {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
    }

    .video-wrapper video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 25px;
      @media screen and (max-width: $media-query-s) {
        border-radius: 16px;
      }
    }
  }
}
