@import "src/mixins/styles";

.aboutSubscription-page {
  width: 100%;
  border-radius: 15px;
  min-height: 73vh;
  height: calc(100%);
  backdrop-filter: blur(14px);
  background: url("../../../assets/images/Login/login_bg.svg") center center no-repeat, rgba(255, 255, 255, 0.9);
  font-family: "TT Norms Pro", sans-serif;

  @media screen and (max-width: $media-query-l) {
    min-height: 69vh;
  }
  @media screen and (max-width: $media-query-m) {
    min-height: 63vh;
  }
  @media screen and (max-width: $media-query-s) {
    min-height: 58vh;
  }

  .aboutSubscription {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(14px);
    border-radius: 15px;
    min-height: 45vh;
    @media screen and (max-width: $media-query-m) {
      width: 100%;
      padding: 20px;
    }

    h2 {
      font-family: "TT Norms Pro", sans-serif;
      color: $form-text-header;
      margin-bottom: 20px;
      font-size: $font-size-l;
      font-weight: 400;
      @media screen and (max-width: $media-query-m) {
        white-space: nowrap;
        font-size: $font-size-ml;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        font-weight: 400;
        font-style: normal;
        margin-bottom: 10px;
        font-size: 18px;
        color: $form-text-color;

        @media screen and (max-width: $media-query-m) {
          font-size: $font-size-m;
        }
      }

      i {
        font-weight: 600;
      }
    }

    &__price {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 18px;
      color: $form-text-color;
      margin-top: 20px;
      font-weight: lighter;
    }

    &__link {
      margin-top: 20px;
      text-align: center;

      a {
        cursor: pointer;
        border: 0;
        padding: 13px 24px 12px 24px;
        border-radius: 8px;
        background: $subscription-button-color;
        color: #f9fafb;
        text-decoration: none;
        font-weight: lighter;
        font-style: normal;
        font-size: 18px;
        @media screen and (max-width: $media-query-m) {
          font-size: $font-size-m;
          padding: 10px 20px 10px 20px;
        }
      }
    }
  }
}
