@import "src/mixins/styles";

.custom-confirm-box {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 32px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 28px 60px 0 rgba(154, 154, 175, 0.27);

  @media screen and (max-width: $media-query-m) {
    width: 90%;
    margin: 0 auto;
  }

  &__buttons {
    @include flex-center;
    margin-top: 24px;
    text-align: center;

    @media screen and (max-width: $media-query-m) {
      gap: 8px;
    }
  }

  p {
    margin-bottom: 16px;
    color: #374151;

    @media screen and (max-width: $media-query-s) {
      font-size: $font-size-m;
    }
  }

  button {
    margin: 0 16px;
    padding: 10px 30px;
    min-width: 50px;
    cursor: pointer;
    background-color: $form-text-header;
    color: #f9fafb;
    border: none;
    border-radius: 4px;
    font-family: "TT Norms Pro", sans-serif;
    font-weight: 200;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $subscription-button-color;
    }
  }
}
