@import "src/mixins/styles";

.landing-header {
  display: flex;
  position: relative;
  width: 100%;
  padding: 30px 64px 10px 64px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  @include flex-center-between;

  .userEmail {
    position: absolute;
    right: 64px;
    top: 10px;
    color: #374151;
    &:hover {
      color: #ffb300;
    }
  }

  @media screen and (max-width: $media-query-l) {
    display: flex;
    align-items: center;
    .userEmail {
      right: 64px;
    }
  }
  @media screen and (max-width: $media-query-m) {
    padding: 30px 32px 10px 32px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .userEmail {
      right: 32px;
    }
  }
  @media screen and (max-width: $media-query-s) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .userEmail {
      //top: 5px;
      right: 32px;
    }
  }

  &_links {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 39px;
    @media screen and (max-width: $media-query-l) {
      display: flex;
      align-items: center;
      padding: 12px;
      gap: 19.5px;
    }
    @media screen and (max-width: $media-query-m) {
      align-items: center;
      gap: 19.5px;
      flex-direction: column;
      width: 100%;
      background-color: #f9fafb;
      z-index: 1;
      display: none;
      &.menu-open {
        display: flex;
      }
    }
    @media screen and (max-width: $media-query-s) {
      align-items: center;
      flex-direction: column;
      gap: 19.5px;
      display: none;
      &.menu-open {
        display: flex;
      }
    }

    a {
      font-weight: 500;
      text-decoration: none;
      color: #374151;
      text-align: center;
      font-size: $font-size-ml;
      line-height: 120%;
      @media screen and (max-width: $media-query-l) {
        //font-size: $font-size-m;
        white-space: nowrap;
        align-items: center;
      }
      @media screen and (max-width: $media-query-m) {
        //font-size: $font-size-m;
        white-space: nowrap;
        align-items: center;

        text-align: center;
        width: 100%;
        font-size: $font-size-ml;
        color: #374151;
        text-decoration: none;

        button {
          padding: 8px;
        }

        &:hover {
          background-color: #f0f4f8;
        }
      }
      @media screen and (max-width: $media-query-s) {
        font-size: $font-size-m;
        white-space: nowrap;
        align-items: center;
      }

      &:hover {
        color: #ffb300;
      }
    }
  }

  &_features {
    display: flex;
    align-items: center;
    gap: 26px;

    a {
      text-decoration: none;
    }

    &__translation {
      display: flex;
      align-items: center;

      button {
        cursor: pointer;
        border: none;
        background: none;
        @media screen and (max-width: $media-query-m) {
          font-size: $font-size-s;
        }

        &:hover {
          color: #ffb300;
        }
      }
    }

    .logoutButtonAndEmail {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__feedback {
      cursor: pointer;
      border: 0;
      padding: 13px 24px 12px 24px;
      width: fit-content;
      white-space: nowrap;
      border-radius: 8px;
      background: #ffb300;
      color: #f9fafb;
      font-size: $font-size-ml;
      font-family: "TT Norms Pro", sans-serif;
      font-weight: 500;

      @media screen and (max-width: $media-query-m) {
        padding: 8px 16px 8px 16px;
        font-size: $font-size-m;
      }
    }

    @media screen and (max-width: $media-query-m) {
      margin-bottom: 4px;
      justify-content: center;
      width: 100%;
      background-color: #f9fafb;
      z-index: 1;
      padding: 12px;
      display: none;
      &.menu-open {
        display: flex;
        width: 100%;
        height: 100%;
      }

      button {
        padding: 8px 14px;
        border-radius: 8px;
        font-size: $font-size-ml;
        font-family: "TT Norms Pro", sans-serif;
        font-weight: 500;

        &:hover {
          background-color: #374151;
        }
      }
    }
  }
}

#header-toggle {
  position: absolute;
  right: 32px;
  top: 50px;
  transform: translateY(-50%);
  width: 30px;
  cursor: pointer;
  z-index: 2;
}

#header-toggle span,
#header-toggle span:before,
#header-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 30px;
  background: #374151;
  position: absolute;
  left: 0;
  display: block;
  content: "";
}

#header-toggle span:before {
  top: -8px;
}

#header-toggle span:after {
  top: 8px;
}

#header-toggle span,
#header-toggle span:before,
#header-toggle span:after {
  transition: all 0.5s ease-in-out;
}

#header-toggle.active span {
  background-color: transparent;
}

#header-toggle.active span:before {
  transform: rotate(45deg);
  top: 0;
}

#header-toggle.active span:after {
  transform: rotate(-45deg);
  top: 0;
}

@media screen and (min-width: 769px) {
  #header-toggle {
    display: none;
  }
}
